import React from "react";
import GeneralAccordion from "../../common/accordion/generalAccordion";

const accordionData = [
  {
    question: "What is Gas Aggregator Company Nigeria Limited “GACN”",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
  <li>Gas Aggregator Company Nigeria Ltd/Gte (“GACN”) was originally incorporated in January 2010 as “Gas Aggregator Company Nigeria Limited” further to the National Gas Supply and Pricing Regulation, 2008 (the “Regulations”). It was established as the “Strategic Aggregator” for the implementation of the Nigerian Gas Master Plan and management of domestic supply of gas to the Nigerian market.</li>
  <li>Following the enactment of the Petroleum Industry Act, 2021 (the “PIA”), GACN was re-registered as a company limited by guarantee on the 20th of October, 2023 and was issued the domestic gas aggregator license in compliance with the requirements of the PIA.</li>
  <li>The PIA further solidifies the strategic position of GACN by, amongst others, mandating the Aggregator to support and manage the implementation of the Domestic Gas Delivery Obligation (the “DGDO” formerly the Domestic Supply Obligation) for the purpose of enhancing natural gas utilization in Nigeria.<br/>This is consistent with the objectives of the Federal Government of Nigeria (the “FGN”) to pursue a sustainable growth path by leveraging gas for the economic growth of the country, and for the enhancement of Nigeria’s energy transition.</li>
  <li>GACN’s primary mandate is to ensure adequate supply of gas to the following “Strategic Sectors” of the domestic market:
    <ol  class="list-[lower-alpha] ml-5">
      <li>Power Sector (power generation companies)</li>
      <li>Gas Based Industries (GBI) (e.g. fertilizer or methanol plants); and</li>
      <li>Commercial Sector (to be determined)<br/>for the purpose of enhancing natural gas usage to achieve the much-desired industrialization in Nigeria.</li>
    </ol>
  </li>
</ol>
`,
  },
  {
    question: "What are the functions of the Aggregator?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>As the aggregator, GACN acts as an intermediary between suppliers and purchasers of natural gas in the Nigerian domestic market and ensures the supply of gas to the Strategic Sectors in accordance with the PIA.  In this vein, GACN receives, and processes gas requests and facilitates the negotiation, execution and administration of Gas Sale and Aggregation Agreements (GSAAs) and similar gas sale agreements.</li>
    <li>GACN also supports the implementation of the DGDO.</li>
    <li>GACN implements a Gas Management Model through which the demand and supply of gas for utilization within Nigeria shall be monitored.</li>
    <li>GACN also operates a nomination and balancing mechanism for equitable curtailment of natural gas deliveries in cooperation with the Nigerian Midstream and Downstream Petroleum Regulatory Authority whenever demand and supply expediencies require.</li>
    <li>GACN ensures transparency of transactions between natural gas suppliers and wholesale customers of the Strategic Sectors.</li>
    <li>GACN manages an escrow account into which buyers contribute their payments for the marketable natural gas received and from which the Aggregator shall pay the sellers for their supplies of natural gas.</li>
</ol>
`,
  },
  {
    question: "Is GACN a Gas Regulator?",
    answer: `
<p class="list-[decimal] text-grey-600 text-base md:text-lg !leading-[32px]">
    No. The Aggregator is not a regulator but a regulated entity. It is regulated through the Nigerian Midstream and Downstream Petroleum Regulatory Authority and Nigerian Upstream Petroleum Regulatory Commission. GACN interfaces with these regulators on due diligence process for buyers, demand rationing criteria, and “DGDO” previously known as “DSO” management.
</p>
`,
  },
  {
    question: "What is DGDO/DSO?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>DSO means Domestic Supply Obligation.</li>
     <li>In a bid to kick start the Nigeria Domestic Gas Market, the FGN introduced the DSO volumes through its National Gas Supply and Pricing Regulations 2008 (the “Regulations”).</li>
     <li>The Regulations mandate all gas (Associated Gas & Non-Associated Gas) producers to dedicate a specific portion of their gas reserves and production for supply to the Nigerian domestic market. These DSO volumes were assigned by the FGN through the erstwhile Department of Petroleum Resources.</li>
     <li>Following the enactment of the PIA, however, the DSO is now known as Domestic Gas Delivery Obligation (DGDO) and DGDO volumes are now assigned by the FGN through the Nigerian Upstream Petroleum Regulatory Commission.</li>
     <li>Periodical reviews of the DGDO will take place to reflect the changing demographics of the demand and supply landscape.</li>
</ol>
`,
  },
  {
    question: "What are the penalties for non-compliance with DGDO?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>According to the PIA, suppliers who do not comply with their DGDO shall:
    <ol class="list-[lower-alpha] ml-5">
      <li>Pay for the volumes not supplied based on the take or pay provision of the executed GSAA or at a price of US$3.50/MMBtu, whichever is higher (This is an after-tax penalty, not cost recoverable).</li>
      <li>Not be allowed to supply gas to any export project, in addition to any other penalties the Minister of Petroleum Resources may deem fit.</li>
    </ol>
  </li>
</ol>
`,
  },
  {
    question: "Who can buy gas from GACN?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>Power generation companies whose sole business is to generate power for the national grid i.e. Power Sector buyers.</li>
    <li>Companies use natural gas as feedstock for their products, such as fertilizer and petrochemicals. i.e. GBI buyers.</li>
    <li>Local distribution companies who sell natural gas to commercial and manufacturing companies in the domestic market e.g. NNPC Gas Marketing Limited (NGML), Gas Link Nigeria Limited, etc.</li>
    <li>Mini-LNG companies and CNG Companies.</li>
</ol>
`,
  },
  {
    question: "How does a buyer purchase gas through GACN?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>A potential Buyer logs a gas request with GACN through an introductory letter or through GACN’s website indicating gas use, volume plant location etc.</li>
    <li>GACN then invites the Buyer to an introductory meeting wherein the Buyer is expected to make a formal presentation of the proposed project(s). GACN then presents its roles and responsibilities in the domestic gas market and gives an outline of the industry-approved due diligence assessment process.</li>
    <li>The Buyer makes payment of a mandatory “New Buyer’s Access Contribution” to access gas within the GACN framework.</li>
    <li>On confirmation of receipt of payment of the above, GACN issues due diligence documents to the Buyer.</li>
    <li>The Buyer receives and acknowledges receipt of the Gas Request Form and Due Diligence Questionnaire (the “DDQ”).</li>
    <li>Buyer completes Gas Request Form (“GRF”) and DDQ.</li>
    <li>Simultaneously, GACN and the Buyer execute a Mutual Non-Disclosure Agreement.</li>
    <li>The Buyer submits the completed GRF and DDQ to GACN.</li>
    <li>GACN evaluates the Buyer’s submission based on pre-approved methodology. As part of the process, GACN carries out a site verification visit to the Buyer’s proposed project/facilities and if successful at the end of the evaluation, progresses to the Gas Demand Pool.</li>
    <li>GACN issues a Gas Purchase Order (GPO) which is an instrument to formally allocate gas seller(s) to Buyers who are Pre-Qualified through the aforementioned Due Diligence evaluation process and subsequently accepted into the Gas Demand pool.</li>
</ol>
<p class="pt-4 text-grey-600 text-base md:text-lg !leading-[32px]">
<span class="text-black font-semibold">Note: </span>
 GACN issues a GPO subject to gas availability and delivery time of the Buyer’s proposed project.
</p>
`,
  },
  {
    question: "Is GACN a gas producer’s or sellers’ cartel?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>GACN is not a cartel of gas producers as it acts to protect the interest of both buyers and sellers, as well as other stakeholders, in the Nigerian domestic gas market.</li>
    <li>GACN conducts due diligence on Buyers before allocating their requested volumes to Sellers based on their DGDO.</li>
    <li>GACN similarly interfaces with Sellers to ensure they have firm supply plans that will meet the requirement of Buyers.</li>
    <li>GACN has operated in a fairly independent manner till date with midstream and downstream sector participants and gas Buyers having representation on GACN’s Board of Directors.</li>
</ol>
`,
  },
  {
    question: "What are some assumed roles that GACN does not perform?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>GACN does not produce, process, or transport natural gas.</li>
    <li>GACN does not buy or sell gas.</li>
    <li>GACN does not build or own gas pipelines or gas infrastructure.</li>
    <li>GACN is not a regulator.</li>
    <li>GACN does not determine the prices for purchase of natural gas.</li>
</ol>
`,
  },
  {
    question: "What are the unique elements of the GACN value proposition?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>GACN’s business is operated on a cost recovery basis, and its annual cost budget is funded through annual operating contributions (“AOCs”) by gas Sellers and gas Buyers.</li>
    <li>AOCs are used to fund GACNs operating expenditure.</li>
    <li>GACN is a “not for profit” entity, as such, any unspent AOC is carried forward to fund the following years’ budget.</li>
    <li>The foregoing enables GACN to remain commercially neutral in the implementation of its mandate.</li>
</ol>
`,
  },
  {
    question: "Should GACN require funding autonomy?",
    answer: `
<p class="list-[decimal] text-grey-600 text-base md:text-lg !leading-[32px]">
       In light of the enactment of the PIA, it is contemplated that GACN’s funding may be based partly on AOCs and partly on minimal fees as may be approved by GACN’s Board.
</p>
`,
  },
  {
    question:
      "Does GACN facilitate the procurement of Liquefied Petroleum Gas (LPG)?",
    answer: `
<p class="list-[decimal] text-grey-600 text-base md:text-lg !leading-[32px]">
       No, this is currently not included in GACN’s mandate. 
</p>
`,
  },
  {
    question: "Is GACN a Federal Government Agency or Parastatal?",
    answer: `
<p class="list-[decimal] text-grey-600 text-base md:text-lg !leading-[32px]">
       No. However, it performs roles which are pivotal to the attainment of the FGN’s objectives for the Nigerian domestic gas sector.
</p>
`,
  },
  {
    question: "Can GACN facilitate procurement of Gas for individuals?",
    answer: `
<p class="list-[decimal] text-grey-600 text-base md:text-lg !leading-[32px]">
       No, GACN only facilitates industrial and wholesale procurement of gas.
</p>
`,
  },
  {
    question:
      "Is GACN a temporary entity that is meant to have a sunset timeline?",
    answer: `
<ol class="list-[decimal] ml-5 space-y-8 text-grey-600 text-base md:text-lg !leading-[32px]">
    <li>Some commentators often confuse GACN’s price aggregation role as its sole mandate and therefore question its continued relevance upon the future attainment of “willing buyer-willing seller.</li>
    <li>While the price aggregation role may have a sunset timeline, GACN is poised to evolve in dynamic ways to continue to support the development of the Nigerian domestic gas market.</li>
</ol>
`,
  },
  {
    question:
      "What is responsible for the non-commencement of price aggregation?",
    answer: `
<p class="list-[decimal] text-grey-600 text-base md:text-lg !leading-[32px]">
       The non-commencement of price aggregation is largely due to the power sector payment challenges, which is a hydra-headed issue currently being addressed. Stakeholder engagement and alignment on aggregation is ongoing and part of the option under consideration is to aggregate only for GBI and the Commercial sector, while the Power Sector related payments would be handled on a “pass through” basis.
</p>
`,
  },
];

export default function FAQsAccordion() {
  return (
    <section className="pb-20 mt-[-34px] md:mt-[-80px]">
      <div className="container">
        <GeneralAccordion accordionData={accordionData} type="faq" />
      </div>
    </section>
  );
}
